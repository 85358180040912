import React from "react";
import parser from "html-react-parser";
import { Link } from "react-router-dom";
import VideoBlock3 from "../VideoBlock/VideoBlock3";

const Hero15 = ({ data }) => {
  const { subTitle, title, text, imgSrc, videoSrc } = data;
  return (
    <div className="st-hero st-style1 st-type2">
      <div className="container">
        <div className="st-height-b120 st-height-lg-b80" id="home"></div>
        <div className="st-hero-in">
          <div className="st-hero-text">
            <h4 className="st-hero-mini-title st-pink"> {parser(subTitle)}</h4>
            <h1 className="st-hero-title l13">{parser(title)}</h1>
            <div className="st-hero-subtitle">{parser(text)}</div>
            <div className="btn-flex">
              <Link
                to="https://booking.setmore.com/scheduleappointment/932f19c8-6989-4716-93c5-c73ac31d511b"
                className="st-btn st-style1 st-color4 bg-blue  st-size-medium st-smooth-move"
              >
                Make an Appointment 
              </Link>
              <Link
                to="/contact-us"
                className="st-btn st-style1 st-color4 bg-wht  st-size-medium st-smooth-move"
              >
               Contact us
              </Link>
            </div>
          </div>
          <div className="st-height-b120 st-height-lg-b80"></div>
          {/* <VideoBlock3 imgSrc={imgSrc} videoSrc={videoSrc} /> */}
        
        </div>
      </div>
    </div>
  );
};

export default Hero15;
