import { React, useEffect, useState } from "react";
import HeroSlider from "../Slider/HeroSlider";
import { Link as ScrollLink } from "react-router-dom";
import TextTransition, { presets } from "react-text-transition";

const Hero5 = ({ data }) => {
  const { sliderImages, title } = data;

  const [index, setIndex] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000 // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <>
      <div className="st-height-b125 st-height-lg-b80" id="home"></div>
      <div id="home" className="st-hero-wrap st-color1 overflow-hidden">
        <div className="st-wave-animation" />
        <div className="st-hero st-style1">
          <div className="container">
            <div className="st-hero-text">
              <h1 className="st-hero-title cd-headline slide">
                Cosmetic Dentistry:
                <br /> Transforming Smiles, <br />
                Changing Lives
              </h1>
              <div className="st-hero-subtitle">
                Welcome to your definitive guide on Cosmetic Dentistry,<br/> 
                where science meets art to enhance your smile. Explore<br/>
                teeth whitening, porcelain veneers, and more to achieve <br/>
                a dazzling smile and transform your confidence and  <br/> appearance.
              </div>
              <div className="st-hero-btn">
                <ScrollLink
                  to="https://booking.setmore.com/scheduleappointment/932f19c8-6989-4716-93c5-c73ac31d511b"
                  className="st-btn st-style1 st-size-medium st-color1 st-smooth-move"
                >
                  Appointment
                </ScrollLink>
              </div>
            </div>
          </div>
        </div>
        <HeroSlider data={sliderImages} />
      </div>
    </>
  );
};

export default Hero5;
