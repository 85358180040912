import Steps from "../Components/Regestration/Steps";
import InfoStep from "../Components/Regestration/InfoStep";
import NavigationButton from "../Components/Regestration/NavigationButtons"
import ThankyouStep from "../Components/Regestration/ThankyouStep"
import AddonsStep from "../Components/Regestration/AddonsStep"
import Finishing from "../Components/Regestration/Finishing";
import PlanStep from "../Components/Regestration/PlanStep"
import { useState } from "react";
import { useSelector } from "react-redux";
function App() {

  const page = useSelector((e) => e.page.value)
  const PageDisplay = () => {
    switch (page) {
      case 0:
        return <InfoStep />
      case 1:
        return <PlanStep />
      case 2:
        return <AddonsStep />
      case 3:
        return <Finishing />
      case 4:
        return <ThankyouStep />
    }
  }
  return (
    <section className="FromContainer">
      <div className="Container">
        <Steps />
        <div className="content">
          {PageDisplay()}

          {page != 4 && <NavigationButton />}
        </div>
      </div>
     
    </section>
  );
}

export default App;
