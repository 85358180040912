import { BsFillCalendar2CheckFill } from 'react-icons/bs';

const BookingButton = () => {
  return (
    <div className="st-hero-btn-group d-flex justify-content-center">
      <a
      target='_blank'
        href="https://booking.setmore.com/scheduleappointment/932f19c8-6989-4716-93c5-c73ac31d511b" 
        className="st-btn st-style1 st-size-medium st-color1 st-smooth-move"
        style={{ width: '100%', maxWidth: '300px',textAlign:"center" }} // Adjust max-width as needed
      >
        <BsFillCalendar2CheckFill /> Book Now
      </a>
    </div>
  );
};

export default BookingButton;
