import React from "react";
import Hero3 from "../Components/Hero/Hero3";
import Contact from "../Components/Contact/Contact";
import Department from "../Components/Department/Department";
import About from "../Components/About/About";
import About2 from "../Components/About/About2";
import PostWrapper from "../Components/Post/PostWrapper";
import TestimonialSlider from "../Components/Slider/TestimonialSlider";
import Funfact from "../Components/Funfact/Funfact";
import HomeCta from "../Components/Blocks/Cta";
import BeforeAfter from "../Components/BeforeAfter/BeforeAfter";

const heroData = [
  {
    title:
      "<span> Free Dental Check-Up and   </span>  <br /> <span> X-Rays With Every Booked</span>  <br /><span>  Hygiene Treatment </span> ",
    flex: "none",
    buttonTop: "ACCESSIBLE SMILES",
    buttonBottomLnk:
      "https://booking.setmore.com/scheduleappointment/932f19c8-6989-4716-93c5-c73ac31d511b",
    buttonBottom: "Book - £35",
    bgImg:
      "https://res.cloudinary.com/db1i46uiv/image/upload/v1693907556/lovely-woman-smile_spcbk4.jpg",
  },
  {
    title: "Making mobile Dentistry  <br /> More Affordable",
    flex: "none",
    buttonBottomLnk: "",
    // buttonBottomLnk: "/services/mobile-dentistry",
    buttonBottom: "Register now",
    buttonBottomLnk: "/services/regestration",

    bgGradient:
      "radial-gradient(circle 311px at 8.6% 27.9%, rgb(146,90,39 / 36%) 12.9%, rgb(236 245 254) 91.2%)",
    subTitle:
      "Pav Dental provides virtual consultations for £35, requiring no <br /> appointment or insurance. Schedule easily, connect via video, <br /> and consult  with a dentist from home. ",
    bgImg:
      "https://res.cloudinary.com/db1i46uiv/image/upload/v1717662518/New_Project_12_bvxjhn.png",
  },

  {
    display: "none",
    flex: "flex",
    WhiteSubTitle:
      "<h1>Emergency Dentist</h1> <h2> Covering LEEDS 24 Hours 7 days of the week  including National and Bank Holidays </h2> £45 consultation <br /> £90* out-of-hours consultation ",
    buttonTop: "ACCESSIBLE SMILES",
    buttonBottom: "Call us Now <br /> 0800 1938 786",
    buttonBottomLnk: "tel:0800 1938 786",
    sideBottom: "Book Online",
    sideBottomLnk:
      "https://booking.setmore.com/scheduleappointment/932f19c8-6989-4716-93c5-c73ac31d511b",
    bgImg:
      "https://res.cloudinary.com/db1i46uiv/image/upload/v1716633970/dental-bg-1024x683_drc1la.jpg",
  },
  {
    title:
      "<span> Rediscover Your Youthful  </span> <br /> <span>  Radiance  with Our Premier  </span> <br /> <span>  Aesthetic Treatments </span>",
    buttonTop: "FACIAL AESTHETICS",
    buttonBottom: "Learn more",
    buttonBottomLnk: "/services/aesthetics",
    flex: "none",
    bgImg:
      "https://res.cloudinary.com/db1i46uiv/image/upload/v1693907769/cosmetologists-hands-making-medical-botox-injections-beautiful-blonde-skin-lifting-facial-treatment-beauty-spa_y9cb2v.jpg",
  },
];

const aboutData = {
  title:
    "Comprehensive Dental Services: Book Online, Specialized Care, and Insurance Support",
  subTitle:
    " Providing expert oral surgery, comprehensive dental hygiene assessments, cosmetic dentistry, special care services, and public health insurance assistance, our clinic ensures top-notch care for all your dental needs. Enjoy the convenience of free online appointment booking to easily schedule your visits.",

  avater: {
    img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1724272295/gdc_logo-9b5f8d0667d0a597708b14cf69457ceb_muydhh_1_1_jdhkkt.jpg",
    name: "David Ambrose",
    designation:
      "<span>+</span>  Oral hygiene advisory & assessment <br/>   <span>+</span> Dental public health insurance <br/> <span>+</span> Free online appointment booking <br/> <span>+</span> Various cosmetic dentistry services <br/> <span>+</span> Special care dentistry services<br/> <span>+</span>  Oral surgery ",
  },
  timeTable: [
    {
      day: "Monday",
      startTime: "9:00",
      endTime: "6:00",
    },
    {
      day: "Tuesday",
      startTime: "9:00",
      endTime: "6:00",
    },
    {
      day: "Wednesday",
      startTime: "9:00",
      endTime: "6:00",
    },
    {
      day: "Thursday",
      startTime: "9:00",
      endTime: "6:00",
    },
    {
      day: "Friday",
      startTime: "9:00",
      endTime: "6:00",
    },
    {
      day: "Saturday",
      startTime: "10:00",
      endTime: "4:00",
    },
    {
      day: "Sunday",
      startTime: "10:00",
      endTime: "4:00",
    },
  ],
  contact: "0800 1938 786",
};

const aboutData2 = {
  title: "Exceptional Dentistry That You Can Rely On",
  subTitle:
    "At Pav Dental we hold a strong ethos in delivering the best dental care possible for all of our patients. We believe a healthy and happy smile is true to one’s nature in expression of themselves and we are committed to making sure all of our patients can be confident and satisfied with the care they receive from the moment they walk through our doors. We uphold community values and believe we have an important responsibility in serving our community to the best our ability. <br/><br/> We appreciate no two patients are the same and are committed to delivering care and treatment tailored to fulfil the dental care you deserve. Dentistry is a fundamental aspect of any individual well being and we are determined to ensure excellence no matter what your needs may be.",
  img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1717238472/jonathan-borba-hl6uG9cHW5A-unsplash_hce6yq.jpg",
  avater: {
    img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1717238315/New_Project_11_w7d3ls.png",
    name: "Dr. Hassan Bhojani",
    designation: "Founder & Principal Dentist",
  },
};

const beforeAfterData = {
  bgImg: "",
  beforeImg:
    "https://res.cloudinary.com/db1i46uiv/image/upload/v1717238863/before_fynlhn.jpg",
  afterImg:
    "https://res.cloudinary.com/db1i46uiv/image/upload/v1717238861/after_euabj2.jpg",
};

const testimonialData = [
  {
    img: "images/avatar2.png",
    name: "Best Dentist I've Ever Been to",
    designation: "Executive",
    subTitle:
      "Best dentist I've ever been too, very welcoming and friendly staff, I've always had a fear of dentists but these guys were absolute brilliant made me feel very comfortable and explained what they were going to do before so I had no worries",
  },
  {
    img: "images/avatar3.png",
    name: "Excellent Practice & Great Staff",
    designation: "Biographer",
    subTitle:
      "This was my first visit at this practice, the reception staff were friendly and polite. The dentist I saw was patient with me and gave excellent aftercare advice. This made me feel reassured and relaxed. Excellent practice and great staff.",
  },
  {
    img: "images/avatar4.png",
    name: "Explained Everything Before Any Work Started.",
    designation: "UX Designer",
    subTitle:
      "I would rate this as the best dentist I have ever been to. Hassan, my dentist, made me feel really relaxed through my procedures and explained everything before any work started, so thank you.",
  },
  {
    img: "images/avatar2.png",
    name: "Professional & Compassionate",
    designation: "Executive",
    subTitle:
      "Had a lot done in one session today took nearly an hour was a male dentist don't know his name but was the best dentist I ever had.",
  },
];

const postData = [
  {
    img: "images/blog1.jpg",
    title:
      "Creating a Safe and Healthy Environment: The Benefits of Installing an Air Purifier in Dental Clinics",
    date: "April 26, 2023",
    author: "Dr Bhojani",
    authorLink: "",
    subTitle:
      "Installing an air purifier like Mars Purifier in a dental clinic improves air quality, enhances staf...",
    postLink: "/post/post_details",
  },
];

const Home = () => {
  return (
    <>
      <div className="st-height-b125 st-height-lg-b80" id="home"></div>
      <Hero3 data={heroData} />
      <HomeCta />
      <About data={aboutData} />
      <Department />
      <About2 data={aboutData2} />
      <Funfact />
      <TestimonialSlider data={testimonialData} />
      <BeforeAfter data={beforeAfterData} />
      <PostWrapper data={postData} />
      <Contact />
    </>
  );
};

export default Home;
