import React, { useState, useEffect } from "react";
import VideoSlider from "../../Components/Slider/VideoSlider";
import SectionHeading from "../../Components/SectionHeading/SectionHeading";

const PracticePolicy = () => {
  const [documents, setDocuments] = useState([
    {
      id: 1,
      title: "Accessible Information Standards Policy",
      url: "/policies/Accessible Information Standards Policy.pdf",
      filename: "accessible-information-standards-policy.pdf"
    },
    {
      id: 2,
      title: "Adverse Weather Policy",
      url: "/policies/Adverse Weather Policy.pdf",
      filename: "adverse-weather-policy.pdf"
    },
    {
      id: 3,
      title: "Advice Sheet for Preemployment Checks",
      url: "/policies/Advice Sheet for Preemployment Checks.pdf",
      filename: "advice-sheet-for-preemployment-checks.pdf"
    },
    {
      id: 4,
      title: "Business Continuity Disaster Recovery Policy",
      url: "/policies/Business Continuity Disaster Recovery Policy.pdf",
      filename: "business-continuity-disaster-recovery-policy.pdf"
    },
    {
      id: 5,
      title: "CCTV Policy",
      url: "/policies/CCTV Policy.pdf",
      filename: "cctv-policy.pdf"
    },
    {
      id: 6,
      title: "Chaperone Policy",
      url: "/policies/Chaperone Policy.pdf",
      filename: "chaperone-policy.pdf"
    },
    {
      id: 7,
      title: "Clinical Governance and Quality Assurance Policy",
      url: "/policies/Clinical Governance and Quality Assurance Policy.pdf",
      filename: "clinical-governance-and-quality-assurance-policy.pdf"
    },
    {
      id: 8,
      title: "Complaints Policy",
      url: "/policies/Complaints Policy.pdf",
      filename: "complaints-policy.pdf"
    },
    {
      id: 9,
      title: "Consent and Mental Capacity Act Policy",
      url: "/policies/Consent and Mental Capacity Act Policy.pdf",
      filename: "consent-and-mental-capacity-act-policy.pdf"
    },
    {
      id: 10,
      title: "CQC Regulatory Policy",
      url: "/policies/CQC Regulatory Policy.pdf",
      filename: "cqc-regulatory-policy.pdf"
    },
    {
      id: 11,
      title: "Data Security Policy",
      url: "/policies/Data Security Policy.pdf",
      filename: "data-security-policy.pdf"
    },
    {
      id: 12,
      title: "Direct Access Policy",
      url: "/policies/Direct Access Policy.pdf",
      filename: "direct-access-policy.pdf"
    },
    {
      id: 13,
      title: "Disclosure Policy",
      url: "/policies/Disclosure Policy.pdf",
      filename: "disclosure-policy.pdf"
    },
    {
      id: 14,
      title: "Domiciliary Policy",
      url: "/policies/Domiciliary Policy.pdf",
      filename: "domiciliary-policy.pdf"
    },
    {
      id: 15,
      title: "Duty of Candour Policy England",
      url: "/policies/Duty of Candour Policy England.pdf",
      filename: "duty-of-candour-policy-england.pdf"
    },
    {
      id: 16,
      title: "Employee Handbook Policy",
      url: "/policies/Employee Handbook Policy.pdf",
      filename: "employee-handbook-policy.pdf"
    },
    {
      id: 17,
      title: "Environmental and Sustainable Development Policy",
      url: "/policies/Environmental and Sustainable Development Policy.pdf",
      filename: "environmental-and-sustainable-development-policy.pdf"
    },
    {
      id: 18,
      title: "Fire Policy",
      url: "/policies/Fire Policy.pdf",
      filename: "fire-policy.pdf"
    },
    {
      id: 19,
      title: "Fit and Proper Persons Directors Policy",
      url: "/policies/Fit and Proper Persons Directors Policy.pdf",
      filename: "fit-and-proper-persons-directors-policy.pdf"
    },
    {
      id: 20,
      title: "Health Safety SOP Policy",
      url: "/policies/Health Safety SOP Policy.pdf",
      filename: "health-safety-sop-policy.pdf"
    },
    {
      id: 21,
      title: "Human Resources Policy",
      url: "/policies/Human Resources Policy.pdf",
      filename: "human-resources-policy.pdf"
    },
    {
      id: 22,
      title: "Infection Control Manual Policy",
      url: "/policies/Infection Control Manual Policy.pdf",
      filename: "infection-control-manual-policy.pdf"
    },
    {
      id: 23,
      title: "Infection Control Statement Policy",
      url: "/policies/Infection Control Statement Policy.pdf",
      filename: "infection-control-statement-policy.pdf"
    },
    {
      id: 24,
      title: "IT and Communications Systems Policy",
      url: "/policies/IT and Communications Systems Policy.pdf",
      filename: "it-and-communications-systems-policy.pdf"
    },
    {
      id: 25,
      title: "Legionella DUWL Policy",
      url: "/policies/Legionella DUWL Policy.pdf",
      filename: "legionella-duwl-policy.pdf"
    },
    {
      id: 26,
      title: "Lone Working Policy",
      url: "/policies/Lone Working Policy.pdf",
      filename: "lone-working-policy.pdf"
    },
    {
      id: 27,
      title: "Medical Devices Regulation Policy",
      url: "/policies/Medical Devices Regulation Policy.pdf",
      filename: "medical-devices-regulation-policy.pdf"
    },
    {
      id: 28,
      title: "Medical Emergency Resuscitation Collapse Policy",
      url: "/policies/Medical Emergency Resuscitation Collapse Policy.pdf",
      filename: "medical-emergency-resuscitation-collapse-policy.pdf"
    },
    {
      id: 29,
      title: "Medicines Management Policy",
      url: "/policies/Medicines Management Policy.pdf",
      filename: "medicines-management-policy.pdf"
    },
    {
      id: 30,
      title: "Mental Capacity Act Summary 2005",
      url: "/policies/Mental Capacity Act Summary 2005.pdf",
      filename: "mental-capacity-act-summary-2005.pdf"
    },
    {
      id: 31,
      title: "NICE Guidelines Policy",
      url: "/policies/NICE Guidelines Policy.pdf",
      filename: "nice-guidelines-policy.pdf"
    },
    {
      id: 32,
      title: "NICE Recall Intervention Guidelines",
      url: "/policies/NICE Recall Intervention Guidelines.pdf",
      filename: "nice-recall-intervention-guidelines.pdf"
    },
    {
      id: 33,
      title: "Patient Information Policy",
      url: "/policies/Patient Information Policy.pdf",
      filename: "patient-information-policy.pdf"
    },
    {
      id: 34,
      title: "Patient Staff Feedback Policy",
      url: "/policies/Patient Staff Feedback Policy.pdf",
      filename: "patient-staff-feedback-policy.pdf"
    },
    {
      id: 35,
      title: "Prescribing Policy",
      url: "/policies/Prescribing Policy.pdf",
      filename: "prescribing-policy.pdf"
    },
    {
      id: 36,
      title: "Privacy Notice for Patients including children",
      url: "/policies/Privacy Notice for Patients including children.pdf",
      filename: "privacy-notice-for-patients-including-children.pdf"
    },
    {
      id: 37,
      title: "Privacy Notice for Staff",
      url: "/policies/Privacy Notice for Staff.pdf",
      filename: "privacy-notice-for-staff.pdf"
    },
    {
      id: 38,
      title: "Radiation Protection Policy",
      url: "/policies/Radiation Protection Policy.pdf",
      filename: "radiation-protection-policy.pdf"
    },
    {
      id: 39,
      title: "Records Management Record Keepingand Retention Policy",
      url: "/policies/Records Management Record Keepingand Retention Policy.pdf",
      filename: "records-management-record-keeping-and-retention-policy.pdf"
    },
    {
      id: 40,
      title: "Domestic Abuse",
      url: "/policies/Domestic Abuse.pdf", // Replace with the actual path
      filename: "domestic-abuse.pdf"
  },
  {
      id: 41,
      title: "Domestic Violence",
      url: "/policies/Domestic Violence.pdf", // Replace with the actual path
      filename: "domestic-violence.pdf"
  },
  {
      id: 42,
      title: "GDC Standards",
      url: "/policies/GDC Standards.pdf", // Replace with the actual path
      filename: "gdc-standards.pdf"
  },
  {
      id: 43,
      title: "Medical Emergencies in the Dental Practice",
      url: "/policies/Medical Emergencies in the Dental Practice.pdf", // Replace with the actual path
      filename: "medical-emergencies-in-dental-practice.pdf"
  }
  ]);

  const consentForms = [
    {
      id: 1,
      title: "Consent Form - Bridges",
      url: "/forms/Consent form - Bridges.docx",
      filename: "consent-form-bridges.docx"
    },
    {
      id: 2,
      title: "Consent Form - Composite Bonding",
      url: "/forms/Consent form - Composite Bonding.docx",
      filename: "consent-form-composite-bonding.docx"
    },
    {
      id: 3,
      title: "Consent Form - Crowns",
      url: "/forms/Consent form - Crowns.docx",
      filename: "consent-form-crowns.docx"
    },
    {
      id: 4,
      title: "Consent Form - Denture",
      url: "/forms/Consent form - Denture.docx",
      filename: "consent-form-denture.docx"
    },
    {
      id: 5,
      title: "Consent Form - Direct Access",
      url: "/forms/Consent form - Direct access.docx",
      filename: "consent-form-direct-access.docx"
    },
    {
      id: 6,
      title: "Consent Form - Extraction",
      url: "/forms/Consent form - Extraction.docx",
      filename: "consent-form-extraction.docx"
    },
    {
      id: 7,
      title: "Consent Form - Filling Restoration",
      url: "/forms/Consent form - Filling Restoration.docx",
      filename: "consent-form-filling-restoration.docx"
    },
    {
      id: 8,
      title: "Consent Form - Implant",
      url: "/forms/Consent form - Implant.docx",
      filename: "consent-form-implant.docx"
    },
    {
      id: 9,
      title: "Consent Form - IV Sedation",
      url: "/forms/Consent form - IV Sedation.docx",
      filename: "consent-form-iv-sedation.docx"
    },
    {
      id: 10,
      title: "Consent Form - Nightguards",
      url: "/forms/Consent form - Nightguards.docx",
      filename: "consent-form-nightguards.docx"
    },
    {
      id: 11,
      title: "Consent Form - Periodontal Treatment",
      url: "/forms/Consent form - Periodontal Treatment.docx",
      filename: "consent-form-periodontal-treatment.docx"
    },
    {
      id: 12,
      title: "Consent Form - Permission to Book Appointment",
      url: "/forms/Consent form - Permission to book appointment.docx",
      filename: "consent-form-permission-to-book-appointment.docx"
    },
    {
      id: 13,
      title: "Consent Form - Photograph Usage for Teaching",
      url: "/forms/Consent form - Photograph usage for teaching.docx",
      filename: "consent-form-photograph-usage-for-teaching.docx"
    },
    {
      id: 14,
      title: "Consent Form - Photography",
      url: "/forms/Consent form - Photography.docx",
      filename: "consent-form-photography.docx"
    },
    {
      id: 15,
      title: "Consent Form - Radiation and X-Ray Exposure on Pregnant Women",
      url: "/forms/Consent form - Radiation and X ray exposure on Pregnant Women.docx",
      filename: "consent-form-radiation-and-xray-exposure-on-pregnant-women.docx"
    },
    {
      id: 16,
      title: "Consent Form - Refusal of Consent Form",
      url: "/forms/Consent form - Refusal of Consent form.docx",
      filename: "consent-form-refusal-of-consent-form.docx"
    },
    {
      id: 17,
      title: "Consent Form - Root Canal Treatment",
      url: "/forms/Consent form - Root Canal Treatment.docx",
      filename: "consent-form-root-canal-treatment.docx"
    },
    {
      id: 18,
      title: "Consent Form - Sharing Records to Secondary Care",
      url: "/forms/Consent form - Sharing Records to Secondary Care.docx",
      filename: "consent-form-sharing-records-to-secondary-care.docx"
    },
    {
      id: 19,
      title: "Consent Form - Third Party Request",
      url: "/forms/Consent form - Third Party Request.docx",
      filename: "consent-form-third-party-request.docx"
    },
    {
      id: 20,
      title: "Consent Form - Whitening",
      url: "/forms/Consent form - Whitening.docx",
      filename: "consent-form-whitening.docx"
    },
    {
      id: 21,
      title: "Hand Hygiene 2",
      url: "/forms/Hand Hygiene 2.docx",
      filename: "hand-hygiene-2.docx"
    },
  ];

  const poster = [
    {
      id: 1,
      title: "Sepsis Trust Dentist Campaign Poster",
      url: "/images/Sepsis Trust Dentist Campaign Poster.jpg",
      filename: "sepsis-trust-dentist-campaign-poster.jpg",
      img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1733690807/png_9497371_mgwu7r.png"
    },
    {
      id: 2,
      title: "Hand Hygiene",
      url: "/images/Hand Hygiene.png",
      filename: "hand-hygiene.png",
      img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1733690891/jpg_9497384_yhwvwz.png"
    },
   
    
  ];



  return (
    <>
      <div className="st-height-b125 st-height-lg-b80" id="home"></div>
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading subTitle="Comprehensive Policies and Guidelines for Excellence in Dental Practice" title="Pav Dental Documents" />

      <div className="container headingdownload">
        <div className="downloadfilename">
          <div className="filename"><p>File name</p></div>
          <div className="filedownload"><p>Download</p></div>
        </div>
      </div>

      <div className="container mb-5">
        {documents.map((document) => (
          <div className="downloadfile">
            <div className="downfirst">
              <div className="pdfimg">
                <img src="https://res.cloudinary.com/db1i46uiv/image/upload/v1733687666/document_16509217_dvrvoh.png" alt="pdf" />
              </div>
              <div className="filedownload"><p>{document.title}</p></div>
            </div>
            <a
              key={document.id}
              href={document.url}
              download={document.filename}
              className="d-block"
            >
              <p className="downloadable">Download</p>
            </a>
          </div>

        ))}
          {consentForms.map((consentForms) => (
            <div className="downloadfile">
              <div className="downfirst">
                <div className="pdfimg">
                  <img src="https://res.cloudinary.com/db1i46uiv/image/upload/v1733690107/file_14969669_etuilb.png" alt="pdf" />
                </div>
                <div className="filedownload"><p>{consentForms.title}</p></div>
              </div>
              <a
                key={consentForms.id}
                href={consentForms.url}
                download={consentForms.filename}
                className="d-block"
              >
                <p className="downloadable">Download</p>
              </a>
            </div>

          ))}
          {poster.map((poster) => (
            <div className="downloadfile">
              <div className="downfirst">
                <div className="pdfimg">
                  <img src={poster.img} alt="pdf" />
                </div>
                <div className="filedownload"><p>{poster.title}</p></div>
              </div>
              <a
                key={poster.id}
                href={poster.url}
                download={poster.filename}
                className="d-block"
              >
                <p className="downloadable">Download</p>
              </a>
            </div>

          ))}
      </div>

    </>
  );
};

export default PracticePolicy;