import React from "react";
import Plan from "./Plan";
import Arcade from "../../assets/imges/icon-arcade.svg";
import Advanced from "../../assets/imges/icon-advanced.svg";
import Pro from "../../assets/imges/icon-pro.svg";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { info } from "./features/user";

const PlanStep = () => {
  const [age, setAge] = useState('');
  const [showSchool, setShowSchool] = useState(false);
  const [showHighSchool, setShowHighSchool] = useState(false);

  const handleChange = (event) => {
    setAge(event.target.value);
  };

  useEffect(() => {
    if (age === 'under 12 months') {
      setShowSchool(true);
      setShowHighSchool(false);
    } else if (age === 'under 18 years') {
      setShowSchool(false);
      setShowHighSchool(true);
    } else {
      setShowSchool(false);
      setShowHighSchool(false);
    }
  }, [age]);


  return (
    <div className="info">
      <h2>Patient Health</h2>
      <p>Please provide patient health information.</p>
      <form className="form" autoComplete="on">
        <div className="fields">
          <div className="dflex">
            <label>What best describes you?</label>
          </div>
          <select name="sex" >
            <option >Select</option>
            <option>Alzheimer's disease or dementia</option>
            <option>Asthma</option>
            <option>Cancer</option>
            <option>Diabetes</option>
            <option>Epilepsy</option>
            <option>Heart disease</option>
            <option>High blood pressure (hypertension)</option>
            <option>Stroke</option>
            <option>Thyroid disease</option>
          </select>
        </div>
        <div className="fields">
          <div className="dflex">
            <label>What best describes you?</label>
          </div>
          <select name="sex" required>
            <option >Select</option>
            <option>I smoke</option>
            <option>I used to smoke</option>
            <option>I have never smoked</option>
            <option>Prefer not to say</option>
          </select>
        </div>
        <div className="fields">
          <div className="dflex">
            <label>Allergies

            </label>
          </div>
          <input
            type="text"
            placeholder="e.g. Rhinitis"
          />
        </div>
        <div className="fields">
          <div className="dflex">
            <label>How often do you drink alcohol?</label>
          </div>
          <select name="sex" required>
            <option >Select</option>
            <option>Never</option>
            <option>Monthly or less</option>
            <option>2 to 4 times a month</option>
            <option>2 to 3 times a week</option>
            <option>4 or more times a week</option>
            <option>Prefer not to say</option>
          </select>
        </div>
        <div className="fields">
          <div className="dflex">
            <label>Units of alcohol you drink on a typical day?
            </label>
          </div>
          <input
            type="text"
            placeholder="e.g. 2"
          />
        </div>
        <div className="fields">
          <div className="dflex">
            <label>How often have you had six or more units of alcohol?</label>
          </div>
          <select name="sex" required>
            <option >Select</option>
            <option>Never</option>
            <option>Less than monthly</option>
            <option>Monthly</option>
            <option>Weekly</option>
            <option>Daily or almost daily</option>
            <option>Prefer not to say</option>
          </select>
        </div>
        <div className="fields">
          <div className="dflex">
            <label>What is your weight?

            </label>
          </div>
          <input
            type="text"
            placeholder="e.g. 50kg"
          />
        </div>
        <div className="fields">
          <div className="dflex">
            <label>What is your height?

            </label>
          </div>
          <input
            type="text"
            placeholder="e.g. 173cm"
          />
        </div>

      </form>

    </div>
  );
};

export default PlanStep;
