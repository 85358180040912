// Team.js
import React from "react";
import SectionHeading from "../Components/SectionHeading/SectionHeading";
import parser from "html-react-parser";
import waleedImage from "../assets/imges/waleedddd.png";

const team = [
  {
    img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1717570713/Untitled_400_x_400_px_1_mvwp6i.png",
    name: "Dr. Hassan Bhojani MDDR PGDip Res.",
    designation: "Founder & Principal Dentist <br/> GDC: 262970",
    authorLink: "/doctor-profile",
  },
  {
    img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1717571473/Untitled_400_x_400_px_2_po4ocx.png",
    name: "Dr Nighat Bhojani MUDr. MEDICAL",
    designation: "Doctor (Aesthetics Specialist)<br/> GMC: 7318736",
    authorLink: "/doctor-profile",
  },
  {
    img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1717571473/Untitled_400_x_400_px_2_po4ocx.png",
    name: "Raheela Shafiq",
    designation: "Manager",
    authorLink: "/doctor-profile",
  },
  {
    img: waleedImage,
    // img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1717571473/Untitled_400_x_400_px_2_po4ocx.png",
    name: "Waleed Javed",
    // designation: "Treatment Coordinator",
    designation: "Co – Founder and Manager",
    authorLink: "/doctor-profile",
  },
  {
    img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1717571976/Untitled_400_x_400_px_3_rp82bm.png",
    name: "Uwais Ullah",
    designation: "Dental Nurse",
    authorLink: "/doctor-profile",
  },
  {
    img: "https://res.cloudinary.com/db1i46uiv/image/upload/v1717572152/Untitled_400_x_400_px_5_jwngel.png",
    name: "Sayed Shohag Rahman",
    designation: "Marketing Manager",
    authorLink: "/doctor-profile",
  },
];

export default function Team() {
  return (
    <>
      <div className="st-height-b125 st-height-lg-b80" id="home"></div>
      <div className="st-height-b60 st-height-lg-b60" />
      {/* <div className="st-height-b10 st-height-lg-b20" /> */}
      <SectionHeading title="In Memory Of Dr Pav" />
      <div className="grid-container">
        <div className="">
          <h4 style={{ color: " rgb(146, 90, 39)", marginTop: "30px" }}>
            Dr Pavan Amar Singh Bhogal MBA
          </h4>
          <p>
            <br />
            <h6>The Late Dr Pav Bhogal</h6>
            <br />
            <h6>03/07/1992 to 16/08/2023</h6>
            <br />
            <h6>Age: 31</h6>
          </p>
        </div>
        <div className="">
          <img
            src="https://res.cloudinary.com/db1i46uiv/image/upload/v1718456207/Untitled_design_tl2psx_zpubom_woprql.jpg"
            alt=""
          />
        </div>
      </div>

     
      {/* <div className="st-height-b50 st-height-lg-b40" /> */}
      <SectionHeading
        title="Dentists & Staff"
        subTitle="We have a dedicated team consisting of highly skilled dentists and staff who works <br/> relentlessly to preserve your happy smile."
      />
      <div className="st-height-b60 st-height-lg-b60" />

      <div className="grid-class-4 container">
        {team.map((team, index) => (
          <div className="Team" key={index}>
            <img src={team.img} className="image-fluid" alt="" />
            <h4>{team.name}</h4>
            <p>{parser(team.designation)}</p>
          </div>
        ))}
      </div>
      <div className="st-height-b120 st-height-lg-b80" />
    </>
  );
}
