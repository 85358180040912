import React from 'react'
import Hero25 from '../../Components/Hero/Hero25'
import SectionHeading from '../../Components/SectionHeading/SectionHeading'




const PrivacyPolicy = () => {
  return (
    <>
        <div className="st-height-b125 st-height-lg-b80" id="home"></div>
      <div className="st-height-b120 st-height-lg-b80" />
      <SectionHeading title="Privacy Policy" />
      <div className="st-height-b80 st-height-lg-b80" />
      <div className="container">
        <p>Pav Dental* (“Pav Dental*”) operates PavDental.com and may operate other websites. It is Pav Dental*’s policy to respect your privacy regarding any information we may collect while operating our websites.</p>
        <h4>Website Visitors</h4>
        <p>
        Like most website operators, Pav Dental* collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Pav Dental*’s purpose in collecting non-personally identifying information is to better understand how Pav Dental*’s visitors use its website. From time to time, Pav Dental* may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website. Pav Dental* also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on PavDental.com blogs/sites. Pav Dental* only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below, except that commenter IP addresses and email addresses are visible and disclosed to the administrators of the blog/site where the comment was left.</p>
        <h4>Gathering of Personally-Identifying Information</h4>
        <p>Certain visitors to Pav Dental*’s websites choose to interact with Pav Dental* in ways that require Pav Dental* to gather personally-identifying information. The amount and type of information that Pav Dental* gathers depends on the nature of the interaction. For example, we ask visitors who sign up at PavDental.com to provide a username and email address. Those who engage in transactions with Pav Dental* are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Pav Dental* collects such information only insofar as is necessary or appropriate to fulfil the purpose of the visitor’s interaction with Pav Dental*. Pav Dental* does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.</p>
        <h4>Aggregated Statistics</h4>
        <p>Pav Dental* may collect statistics about the behavior of visitors to its websites. Pav Dental* may display this information publicly or provide it to others. However, Pav Dental* does not disclose personally-identifying information other than as described below.</p>
        <h4>Protection of Certain Personally-Identifying Information</h4>
        <p>Pav Dental* discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organisations that (i) need to know that information in order to process it on Pav Dental*’s behalf or to provide services available at Pav Dental*’s websites, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Pav Dental*’s websites, you consent to the transfer of such information to them. Pav Dental* will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Pav Dental* discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Pav Dental* believes in good faith that disclosure is reasonably necessary to protect the property or rights of Pav Dental*, third parties or the public at large. If you are a registered user of an Pav Dental* website and have supplied your email address, Pav Dental* may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what’s going on with Pav Dental* and our products. If you send us a request (for example via email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Pav Dental* takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p>
        <h4>Cookies</h4>
        <p>A cookie is a string of information that a website stores on a visitor’s computer, and that the visitor’s browser provides to the website each time the visitor returns. Pav Dental* uses cookies to help Pav Dental* identify and track visitors, their usage of Pav Dental* website, and their website access preferences. Pav Dental* visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Pav Dental*’s websites, with the drawback that certain features of Pav Dental*’s websites may not function properly without the aid of cookies</p>
        <h4>Business Transfers</h4>
        <p>If Pav Dental*, or substantially all of its assets, were acquired, or in the unlikely event that Pav Dental* goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Pav Dental* may continue to use your personal information as set forth in this policy.</p>
        <h4>Ads</h4>
        <p>Ads appearing on any of our websites may be delivered to users by advertising partners, who may set cookies. These cookies allow the ad server to recognise your computer each time they send you an online advertisement to compile information about you or others who use your computer. This information allows ad networks to, among other things, deliver targeted advertisements that they believe will be of most interest to you. This Privacy Policy covers the use of cookies by Pav Dental* and does not cover the use of cookies by any advertisers.</p>
      <h4>Privacy Policy Changes</h4>
      <p>Although most changes are likely to be minor, Pav Dental* may change its Privacy Policy from time to time, and in Pav Dental*’s sole discretion. Pav Dental* encourages visitors to frequently check this page for any changes to its Privacy Policy. If you have a PavDental.com account, you might also receive an alert informing you of these changes. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
      </div>

         </>
  )
}

export default PrivacyPolicy
